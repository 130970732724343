<template>
  <b-modal
    id="create-invoice-modal"
    @show="getClinicHealthPlans"
    size="lg"
    centered
    hide-header
    @hidden="closeModal"
  >
    <div class="header">
      <div class="header-content">
        <p>Nova fatura</p>
        <v-close class="close-svg" @click="closeModal" />
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="8">
        <b-form-group>
          <label for="health_plan">Convênio</label>
          <multiselect
            id="health_plan"
            :value="healthPlan"
            :options="options"
            @select="selectHealthPlan"
            placeholder="Selecionar"
            :showLabels="false"
            label="label"
            track-by="value"
            class="with-border"
          >
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <label for="closing_date">Data de fechamento</label>
          <date-picker
            id="closing_date"
            format="DD/MM/YYYY"
            :disabled-date="isPastDate"
            class="full"
            v-model="form.closing_date"
            placeholder="Selecionar"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div slot="modal-footer" class="w-100">
      <b-btn
        class="float-right"
        variant="primary"
        :disabled="
          !userHasPermission('FatTissFat2') ||
          !form.clinic_health_plan_id ||
          !form.closing_date
        "
        @click="createInvoice"
      >
        Criar nova fatura
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import { TissInvoice } from '@/modules/tiss/manageGuides/utils/statuses'
import { isPastDate } from '@/utils/dateHelper'

import {
  userHasPermission,
  getCurrentClinic
} from '@/utils/localStorageManager'

export default {
  data: () => ({
    clinic: getCurrentClinic(),
    healthPlan: null,
    options: [],
    form: {
      clinic_health_plan_id: null,
      closing_date: null,
      status:  TissInvoice.INVOICE_STATUS_OPEN
    }
  }),
  components: {
    'v-close': Close
  },
  methods: {
    userHasPermission,
    isPastDate,
    selectHealthPlan(value) {
      this.healthPlan = value
      this.form.clinic_health_plan_id = value.value
    },
    async getClinicHealthPlans() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getActiveInvoicingTissClinicHealthPlans(
          this.clinic.id
        )
        this.options = data.map(el => {
          return {
            value: el.id,
            label: el.health_plan.fantasy_name
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal() {
      this.form = {
        clinic_health_plan_id: null,
        closing_date: null,
        status: 'Em aberto'
      }
      this.$bvModal.hide('create-invoice-modal')
    },
    async createInvoice() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.createInvoicingTissInvoice(this.form)
        this.$toast.success('Fatura criada com sucesso!')
        this.$emit('onCreate', data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#create-invoice-modal {
  .header {
    border-bottom: 1px solid var(--neutral-200);

    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px 10px 0;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .close-svg {
        height: 24px;
        width: 24px;
        fill: var(--neutral-500);
        margin-bottom: 5px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--neutral-000);
    border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 100px;
    border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--neutral-200);
  }
}
</style>
